<template>
  <div>
    <base-item
      :item="{
        icon:'mdi-view-dashboard',
        title: 'Startseite',
        to: '/'
      }"
    />
    <admin />
    <template v-for="e in events">
      <stb-m2021
        v-if="e.type === 'stb_m_2021'"
        :name="e.name"
        :id="e._id"
        :key="e._id"
      />
      <stb-w2023
        v-if="e.type === 'stb_w_2023'"
        :name="e.name"
        :id="e._id"
        :key="e._id"
      />
      <stb-ltf2024
        v-if="e.type === 'stb_ltf_2024'"
        :name="e.name"
        :id="e._id"
        :key="e._id"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { apolloDefault } from '@/plugins/graphql'

const query = `
  _id
  ...on StbM2021 {
    name startDate type
  }
  ...on StbW2023 {
    name startDate type
  }
  ...on StbLtf2024 {
    name startDate type
  }
`

export default {
  name: 'Menu',

  components: {
    admin: () => import('../admin/Menu'),
    StbM2021: () => import('../stb_m_2021/Menu'),
    StbW2023: () => import('../stb_w_2023/Menu'),
    StbLtf2024: () => import('../stb_ltf_2024/Menu')
  },

  data: () => ({
    EventFind: []
  }),

  computed: {
    ...mapGetters(['profile', 'isMaster', 'isVisible']),
    events () {
      return this.EventFind.filter(e => this.isVisible(e.type)).sort((a, b) => a.startDate > b.startDate ? -1 : 1)
    },
    adminOf () {
      return this.profile?.adminOf
    },
    organizerOf () {
      return [...(this.profile?.adminOf || []), ...(this.profile?.organizerOf || [])]
    }
  },

  apollo: apolloDefault('Event', query, '(parent: null)')
}
</script>

<style scoped>

</style>
